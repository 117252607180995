{{#each this}}
  <div class='modal-recipe'>
    <button class='btn-close'>
      <svg class="reciepe-close-icon" height='24' width='24'>
        <path
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          d='M18 6 6 18M6 6l12 12'
        />
      </svg>

    </button>
    <div class='wrap-recipe'>
      {{#if youtubeNorm}}
      <iframe
        class='recipe-video'
        id='vimeo-player'
        src='{{youtubeNorm}}'
        frameborder='0'
        allow='autoplay; encrypted-media; fullscreen'
      ></iframe>
      {{else}}
      <img src="{{notFound}}" alt='' class='recipe-video' />
    {{/if}}

      <div class='recipe-container'>
        <h2 class='title-recipe'>{{title}}</h2>
        <div class='recipe-rating'>
          <div class='card_star-rating card_star-rating-modal'>
          <p class='rating star-rating_value'>{{rating}}</p>
            <svg class='star star-modal' width='12' height='12' id="all-stars" viewBox='0 0 14 14' >
              <path
                id='Star 1'
                d='M6.04894 1.42705C6.3483 0.505742 7.6517 0.505741 7.95106 1.42705L8.79611 4.02786C8.92999 4.43989 9.31394 4.71885 9.74717 4.71885H12.4818C13.4505 4.71885 13.8533 5.95846 13.0696 6.52786L10.8572 8.13525C10.5067 8.3899 10.3601 8.84127 10.494 9.25329L11.339 11.8541C11.6384 12.7754 10.5839 13.5415 9.80017 12.9721L7.58779 11.3647C7.2373 11.1101 6.7627 11.1101 6.41221 11.3647L4.19983 12.9721C3.41612 13.5415 2.36164 12.7754 2.66099 11.8541L3.50604 9.25329C3.63992 8.84127 3.49326 8.3899 3.14277 8.13525L0.930391 6.52787C0.146678 5.95846 0.549452 4.71885 1.51818 4.71885H4.25283C4.68606 4.71885 5.07001 4.43989 5.20389 4.02786L6.04894 1.42705Z'
                sroke='black'
              />
            </svg>
            <svg class='star star-modal' width='12' height='12' id="all-stars" viewBox='0 0 14 14'>
              <path
                id='Star 1'
                d='M6.04894 1.42705C6.3483 0.505742 7.6517 0.505741 7.95106 1.42705L8.79611 4.02786C8.92999 4.43989 9.31394 4.71885 9.74717 4.71885H12.4818C13.4505 4.71885 13.8533 5.95846 13.0696 6.52786L10.8572 8.13525C10.5067 8.3899 10.3601 8.84127 10.494 9.25329L11.339 11.8541C11.6384 12.7754 10.5839 13.5415 9.80017 12.9721L7.58779 11.3647C7.2373 11.1101 6.7627 11.1101 6.41221 11.3647L4.19983 12.9721C3.41612 13.5415 2.36164 12.7754 2.66099 11.8541L3.50604 9.25329C3.63992 8.84127 3.49326 8.3899 3.14277 8.13525L0.930391 6.52787C0.146678 5.95846 0.549452 4.71885 1.51818 4.71885H4.25283C4.68606 4.71885 5.07001 4.43989 5.20389 4.02786L6.04894 1.42705Z'
                sroke='black'
              />
            </svg>
            <svg class='star star-modal' width='12' height='12' id="all-stars" viewBox='0 0 14 14'>
              <path
                id='Star 1'
                d='M6.04894 1.42705C6.3483 0.505742 7.6517 0.505741 7.95106 1.42705L8.79611 4.02786C8.92999 4.43989 9.31394 4.71885 9.74717 4.71885H12.4818C13.4505 4.71885 13.8533 5.95846 13.0696 6.52786L10.8572 8.13525C10.5067 8.3899 10.3601 8.84127 10.494 9.25329L11.339 11.8541C11.6384 12.7754 10.5839 13.5415 9.80017 12.9721L7.58779 11.3647C7.2373 11.1101 6.7627 11.1101 6.41221 11.3647L4.19983 12.9721C3.41612 13.5415 2.36164 12.7754 2.66099 11.8541L3.50604 9.25329C3.63992 8.84127 3.49326 8.3899 3.14277 8.13525L0.930391 6.52787C0.146678 5.95846 0.549452 4.71885 1.51818 4.71885H4.25283C4.68606 4.71885 5.07001 4.43989 5.20389 4.02786L6.04894 1.42705Z'
                sroke='black'
              />
            </svg>
            <svg class='star star-modal' width='12' height='12' id="all-stars" viewBox='0 0 14 14'>
              <path
                id='Star 1'
                d='M6.04894 1.42705C6.3483 0.505742 7.6517 0.505741 7.95106 1.42705L8.79611 4.02786C8.92999 4.43989 9.31394 4.71885 9.74717 4.71885H12.4818C13.4505 4.71885 13.8533 5.95846 13.0696 6.52786L10.8572 8.13525C10.5067 8.3899 10.3601 8.84127 10.494 9.25329L11.339 11.8541C11.6384 12.7754 10.5839 13.5415 9.80017 12.9721L7.58779 11.3647C7.2373 11.1101 6.7627 11.1101 6.41221 11.3647L4.19983 12.9721C3.41612 13.5415 2.36164 12.7754 2.66099 11.8541L3.50604 9.25329C3.63992 8.84127 3.49326 8.3899 3.14277 8.13525L0.930391 6.52787C0.146678 5.95846 0.549452 4.71885 1.51818 4.71885H4.25283C4.68606 4.71885 5.07001 4.43989 5.20389 4.02786L6.04894 1.42705Z'
                sroke='black'
              />
            </svg>
            <svg class='star star-modal' width='12' height='12' id="all-stars" viewBox='0 0 14 14'>
              <path
                id='Star 1'
                d='M6.04894 1.42705C6.3483 0.505742 7.6517 0.505741 7.95106 1.42705L8.79611 4.02786C8.92999 4.43989 9.31394 4.71885 9.74717 4.71885H12.4818C13.4505 4.71885 13.8533 5.95846 13.0696 6.52786L10.8572 8.13525C10.5067 8.3899 10.3601 8.84127 10.494 9.25329L11.339 11.8541C11.6384 12.7754 10.5839 13.5415 9.80017 12.9721L7.58779 11.3647C7.2373 11.1101 6.7627 11.1101 6.41221 11.3647L4.19983 12.9721C3.41612 13.5415 2.36164 12.7754 2.66099 11.8541L3.50604 9.25329C3.63992 8.84127 3.49326 8.3899 3.14277 8.13525L0.930391 6.52787C0.146678 5.95846 0.549452 4.71885 1.51818 4.71885H4.25283C4.68606 4.71885 5.07001 4.43989 5.20389 4.02786L6.04894 1.42705Z'
                sroke='black'
              />
            </svg>
          </div>
          <p class='time-cooking'>{{time}} min</p>
        </div>
        <div class='overflow-scroll'>
          <ul class='recipe-ingridient'>

          </ul>
        </div>
      </div>
    </div>
    <ul class='tags'>

    </ul>
  <div class="recipe-text"></div>
    <div class='btn'>
      <button type='button' class='btn-addToFavorite'>{{btnText}}</button>
      <button type='button' class='btn-giveARating'>Give a rating</button>
    </div>
  </div>
{{/each}}