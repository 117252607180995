import { load, remove, save } from './localStorageJSON';

export function heartsFillStorage() {
  const cardFavouritesBtns = document.querySelectorAll('.card_favourites_btn');

  let storedData = load('cardData');
  if (storedData) {
    const identArray = storedData.map(item => item.ident);

    cardFavouritesBtns.forEach(button => {
      const cardId = button.closest('li').dataset.id;
      const whiteHeart = button.parentNode.querySelector('.card_heart');

      if (identArray.includes(cardId)) {
        button.classList.add('heart-filled');
        whiteHeart.classList.add('heart-filled');
      } else {
        button.classList.remove('heart-filled');
        whiteHeart.classList.remove('heart-filled');
      }
    });
  } else {
    cardFavouritesBtns.forEach(button => {
      const whiteHeart = button.parentNode.querySelector('.card_heart');
      button.classList.remove('heart-filled');
      whiteHeart.classList.remove('heart-filled');
    });
  }
}
